<template>
  <section class="wrapper"
           id="schedule">
    <div class="content-wrapper">
      <h1 class="section-title">
        Расписание
      </h1>

      <div class="table">
        <div class="header-line">
          <div class="">
            Время прилета
          </div>

          <div class="">
            Трансляция
          </div>

          <img src="@/assets/schedule-gif.gif"
               class="w-100 desktop" />
        </div>

        <div class="table-line">
          <div class="">
            11:00
          </div>

          <div class="">
            Начало трансляции
          </div>
        </div>

        <div class="table-line">
          <div class="">
            11:00 - 11:10
          </div>

          <div class="">
            Вступительная речь, представление спикеров мероприятия
          </div>
        </div>

        <div class="table-line">
          <div class="">
            11:10 - 11:35
          </div>

          <div class="">
             «Контрацепция высшего пилотажа. ТОП-7 фактов о новом оральном <br>
             контрацептиве», профессор, д.м.н. Аганезова Н.В.
          </div>
        </div>

        <div class="table-line">
          <div class="">
            11:35 - 11:55
          </div>

          <div class="">
            «Эстетрол – умный нативный эстроген с уникальным воздействием на ткани», профессор, д.м.н. Кузнецова И.В.
          </div>
        </div>

        <div class="table-line">
          <div class="">
            11:55 - 12:20
          </div>

          <div class="">
            «Новая комбинация «эстетрол+ дроспиренон» взломает гормонофобию»,
            профессор, д.м.н. Тихомиров А.Л.
          </div>
        </div>

        <div class="table-line">
          <div class="">
            12:20 - 12:40
          </div>

          <div class="">
            Интервью со специальным гостем Юлией Крыловой, <br> пилотом реактивного самолета
          </div>
        </div>

        <div class="table-line">
          <div class="">
            12:40 - 13:00
          </div>

          <div class="">
            Сессия Q&A, подведение итогов и окончание форсайт-сессии
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Schedule',

  data: () => ({
  }),

  computed: {
  },

  methods: {
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  padding-top: 100px;
  padding-bottom: 110px;
  padding-left: 60px;
  padding-right: 60px;
  background-color: $violet;
  background-image: url('@/assets/schedule-bg.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;

  @media(max-width: 576px){
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
    background-image: none;
  }
}

.content-wrapper {
  .section-title {
    color: $white;
    margin-bottom: 25px;
  }

  .table {
    .header-line,
    .table-line {
      display: flex;
      color: $white;
      font-weight: 600;
      font-family: $regular;
      font-size: 21px;
      height: 52px;
      border-bottom: 4px solid $violet;

      @media(max-width: 768px){
        font-size: 14px;

        br {
          display: none;
        }
      }

      @media(max-width: 370px){
        font-size: 10px;
      }

      div {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &:first-child {
          max-width: 288px;
          background-color: $rose;
          border-right: 4px solid $violet;
          flex-shrink: 0;

          @media(max-width: 768px){
            flex-shrink: inherit;
          }
        }

        &:nth-child(2) {
          max-width: 256px;
          background-color: $blue;
          flex-shrink: 0;

          @media(max-width: 768px){
            max-width: 256px;
            flex-shrink: 0;
          }

          @media(max-width: 370px){
            max-width: 226px;
          }
        }
      }
    }

    .table-line {
      background-color: $white;
      height: 71px;
      border-bottom: none;
      margin-bottom: 4px;
      padding-top: 0px;

      div {
        height: 100%;
        width: 100%;

        &:first-child {
          max-width: 288px;
          border-right: 4px dashed $violet;
          background-color: $white;
          color: $rose;
          font-size: 36px;
          font-weight: 600;

          @media(max-width: 768px){
            font-size: 16px;
            flex-shrink: inherit;
            text-align: left;
            justify-content: center;
            padding-left: 15px;
          }

          @media(max-width: 370px){
            font-size: 12px;
            padding-left: 5px;
          }
        }

        &:nth-child(2) {
          max-width: none;
          color: $dark-grey;
          font-size: 19px;
          font-weight: 400;
          line-height: 120%;
          background-color: $white;
          justify-content: flex-start;
          padding-left: 30px;
          flex-shrink: inherit;
          text-align: left;

          @media(max-width: 999px){
            font-size: 16px;
          }

          @media(max-width: 768px){
            font-size: 10px;
            line-height: 120%;
            padding: 0 10px;
            flex-shrink: 0;
            max-width: 256px;
          }

          @media(max-width: 370px){
            max-width: 226px;
          }
        }
      }
    }
  }
}
</style>
