import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import VueScrollTo from 'vue-scrollto'
import vClickOutside from 'v-click-outside'
import { BootstrapVue } from 'bootstrap-vue'
import VueCountdown from '@chenfengyuan/vue-countdown'

import '@/styles/scss/_templates.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueScrollTo)
Vue.use(BootstrapVue)
Vue.use(vClickOutside)
Vue.component(VueCountdown.name, VueCountdown)

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.prototype.$http = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
