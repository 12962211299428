<template>
  <div id="app">
    <HeaderRegion />

    <Plug />

    <Registration />

    <router-view/>

    <FooterRegion />
  </div>
</template>

<script>
import Plug from '@/components/modals/Plug.vue'
import HeaderRegion from '@/components/HeaderRegion.vue'
import FooterRegion from '@/components/FooterRegion.vue'
import Restriction from '@/components/modals/Restriction.vue'
import Registration from '@/components/modals/Registration.vue'

export default {

  components: {
    Plug,
    Restriction,
    HeaderRegion,
    FooterRegion,
    Registration,
  },

  mounted(){
  }
}
</script>

<style lang="scss">

</style>
