<template>
  <header class="">
    <div class="header-wrapper">
      <div class="desktop d-flex align-items-center justify-content-between">
        <router-link :to="{ path: '/' }"
                     class="header-link">
          <img src="@/assets/logo.svg" />
        </router-link>

        <div class="d-flex align-items-center">
          <router-link :to="{ path: '/#broadcast' }"
                       v-scroll-to="'#broadcast'"
                       class="header-link">
            Трансляция
          </router-link>

          <router-link :to="{ path: '/#schedule' }"
                       v-scroll-to="'#schedule'"
                       class="header-link">
            Расписание
          </router-link>

          <router-link :to="{ path: '/#crew' }"
                       v-scroll-to="'#crew'"
                       class="header-link">
            Экипаж
          </router-link>

          <router-link :to="{ path: '/#ask-question' }"
                       v-scroll-to="'#ask-question'"
                       class="header-link">
            Задать вопрос спикеру
          </router-link>
        </div>
      </div>

      <div class="mobile d-flex justify-content-between align-items-center">
        <router-link :to="{ path: '/' }"
                     v-scroll-to="'#promo'"
                     class="header-link">
          <img src="@/assets/logo.svg" />
        </router-link>

        <div class="mobile-icon"
             @click="mobileMenuOpened = !mobileMenuOpened">
          <img src="@/assets/burger-opened.svg"
               v-if="mobileMenuOpened" />

          <img src="@/assets/burger-icon.svg"
               v-if="!mobileMenuOpened" />
        </div>
      </div>
    </div>

    <div class="mobile-menu"
         :class="{ active: mobileMenuOpened }">
       <router-link :to="{ path: '/#broadcast' }"
                    v-scroll-to="'#broadcast'"
                    class="header-link"
                    @click.native="mobileMenuOpened = !mobileMenuOpened">
         Трансляция
       </router-link>

       <router-link :to="{ path: '/#schedule' }"
                    v-scroll-to="'#schedule'"
                    class="header-link"
                    @click.native="mobileMenuOpened = !mobileMenuOpened">
         Расписание
       </router-link>

       <router-link :to="{ path: '/#crew' }"
                    v-scroll-to="'#crew'"
                    class="header-link"
                    @click.native="mobileMenuOpened = !mobileMenuOpened">
         Экипаж
       </router-link>

       <router-link :to="{ path: '/#ask-question' }"
                    v-scroll-to="'#ask-question'"
                    class="header-link"
                    @click.native="mobileMenuOpened = !mobileMenuOpened">
         Задать вопрос спикеру
       </router-link>
    </div>
  </header>
</template>

<script>

export default {
  name: 'HeaderRegion',

  data: () => ({
    mobileMenuOpened: false,
  }),

  computed: {
  },
}
</script>

<style scoped lang="scss">
header {
  position: relative;
}

.header-wrapper {
  height: 94px;
  z-index: 999;
  font-family: $regular;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $violet;
  padding: 0 24px;

  @media(max-width: 999px){
    height: 66px;
    padding: 0 16px;
  }
}

.header-link {
  cursor: pointer;
  color: $white;
  font-weight: 400 !important;
  text-decoration: none;
  font-size: 18px;
  line-height: 100%;
  transition: .4s all ease;
  margin-right: 80px;

  &:hover {
    opacity: .85 !important;
  }

  &:last-child {
    margin-right: 0;
  }

  @media(max-width: 1400px){
    margin-right: 15px;
    font-size: 14px;
  }

  img {
    max-width: 350px;

    @media(max-width: 768px){
      max-width: 236px;
    }
  }
}

.router-link-exact-active {
  font-weight: 700;
}

.mobile-icon {
  transition: 1s all;
  margin-left: 15px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: none;
  margin-left: 15px;
  align-items: center;
  justify-content: center;

  @media(max-width: 999px){
    display: flex;
  }

  &:hover {
    opacity: 0.9;
  }

  img {
    margin: 0;
    transition: 1s all;
  }
}

.mobile-menu {
  background-color: $violet;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 25px;
  position: absolute;
  width: 100vw;
  margin-top: -400px;
  transition: .8s all ease;
  z-index: 999;

  .header-link {
    font-size: 16px;
    display: block !important;
    margin-top: 12px;
    font-weight: 500 !important;
    margin-left: 0;
    margin-right: 0 !important;

    &:first-child {
      margin-top: 0;
    }
  }

  &.active {
    margin-top: 0px;
  }
}

.desktop {
  width: 100%;

  @media(max-width: 999px){
    display: none !important;
  }
}

.mobile {
  width: 100%;

  display: none !important;

  @media(max-width: 999px){
    display: flex !important;
  }
}

.btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white !important;

  &:hover {
    text-decoration: none;
    color: $white !important;
  }
}
</style>
