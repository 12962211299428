<template>
  <footer class="footer-wrapper">
    <div class="text">
      <div class="copyright">
        © ООО «Гедеон Рихтер Фарма», <br class="lg">
        2007-2022
      </div>

      <div class="">
        Чтобы сообщить о нежелательном явлении, заполните
        <a href="doc.docx"
           target="_blank">извещение</a>
        <br class="lg">
        и отправьте на
        <span style="text-decoration: underline;">drugsafety@g-richter.ru.</span>
        <br class="md">
        Ознакомиться с
        <a href="policies.pdf"
           target="_blank">политикой</a>
        конфиденциальности.
      </div>
    </div>

    <img src="@/assets/footer-logo.png"
         class="logo" />
  </footer>
</template>

<script>

export default {
  name: 'FooterRegion',

  data: () => ({
  }),

  computed: {
  },
}
</script>

<style scoped lang="scss">
.footer-wrapper {
  color: #4D4D4D;
  font-size: 16px;
  font-weight: 300;
  font-family: $regular;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 70px;

  @media(max-width: 1300px){
    padding: 15px 30px;
    font-size: 14px;
  }

  @media(max-width: 1200px){
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  @media(max-width: 768px){
    padding: 12px;
    font-size: 12px;
  }

  @media(max-width: 340px){
    font-size: 10px;
  }

  .copyright {
    margin-right: 30px;

    @media(max-width: 1200px){
      margin-top: 20px;
      margin-right: 0;
      width: 100%;
    }
  }

  .text {
    display: flex;
    align-items: center;

    @media(max-width: 1200px){
      flex-direction: column-reverse;
      align-items: flex-start;
      width: 100%;
    }
  }

  a {
    text-decoration: underline;
    color: #4D4D4D;
    transition: .6s all;

    &:hover {
      color: $rose;
    }
  }

  .logo {
    max-width: 230px;

    @media(max-width: 1200px){
      margin-bottom: 15px;
    }
  }
}

.lg {
  @media(max-width: 1300px){
    display: none;
  }
}

.md {
  display: none;

  @media(max-width: 999px){
    display: block;
  }
}
</style>
