<template>
  <div>
    <b-modal
      id="plug-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <h3>
          Трансляция начнется через <br>

          <div class="timer">
            <countdown :time="time">
              <template slot-scope="props">{{ props.days }} дня  :  {{ props.hours }} часов  :  {{ props.minutes }} минут</template>
            </countdown>
          </div>
        </h3>
      </div>

      <div class="d-flex justify-content-center">
        <b-button class="button rose"
                  @click="$bvModal.hide('plug-modal')">Понятно, спасибо!</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    data: () => ({
      time: 0,
    }),

    mounted() {
      this.getTime()
    },

    computed: {
    },

    methods: {
      getTime(){
        const now = new Date()
        const newYear = new Date("February 04, 2023 11:00:00")

        this.time = newYear - now
      }
    }
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }

  h3 {
    width: 100%;
    margin-top: 50px !important;
    margin-bottom: 30px;
    font-weight: 400;
    font-family: $regular;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    color: $violet;

    @media(max-width: 768px){
      font-size: 20px;
    }
  }
}

.timer {
  font-weight: 700;
  margin-top: 20px;
  color: $violet;
}
</style>
