<template>
  <div>
    <b-modal
      id="restriction-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <h3>
          Подтвердите, что вы врач <br>
        </h3>
      </div>

      <div class="buttons-wrapper">
        <b-button class="button rose"
                  @click="accept">
          Да, я являюсь медицинским сотрудником
        </b-button>

        <b-button class="button white mt-3"
                  @click="reject">
          Нет, я не медицинский сотрудник
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="forbid-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
    >
      <div class="header">
        <h3 class="title">
          Сайт доступен только для <br> медицинских работников
        </h3>
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    data: () => ({
    }),

    mounted() {
    },

    computed: {
    },

    methods: {
      accept(){
        localStorage.setItem('allow', true)

        this.$bvModal.hide('restriction-modal')
      },

      reject(){
        localStorage.setItem('restriction', true)

        this.$bvModal.hide('restriction-modal')
        this.$bvModal.show('forbid-modal')
      }
    }
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }

  h3 {
    width: 100%;
    margin-top: 50px !important;
    margin-bottom: 30px;
    font-weight: 400;
    font-family: $regular;
    font-size: 32px;
    text-align: center;
    color: $violet;

    @media(max-width: 768px){
      font-size: 20px;
    }
  }
}

.buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 100px !important;
  padding: 0px !important;
  height: 52px !important;
  width: 100%;
  max-width: 400px;

  @media(max-width: 768px){
    font-size: 12px !important;
  }
}

.white {
  background-color: $white !important;
  color: $rose !important;
  border: 1px solid $rose !important;
}

.title {
  font-size: 30px;
  padding: 40px 0;
  padding-top: 20px;
}
</style>
