<template>
  <section class="wrapper"
           id="broadcast">
    <div class="content-wrapper">
      <h1 class="section-title">
        Трансляция
      </h1>

      <div class="video">
        <iframe
            src="https://facecast.net/w/4d7r3l"
            height="100%"
            width="100%"
            allowfullscreen>
        </iframe>
      </div>

      <!-- <div class="button-wrapper">
        <a href="https://t.me/+NG7WFnTwIwM5YmNi"
           target="_blank"
           alt="telegram"
           class="button blue">
          Перейти в Telegram
        </a>
      </div> -->
    </div>


    <img src="@/assets/gedeon-decoration.svg"
         class="app-decoration" />
  </section>
</template>

<script>

export default {
  name: 'Broadcast',

  data: () => ({
  }),

  computed: {
  },

  methods: {
    openPlug(){
      this.$bvModal.show('plug-modal')
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  padding-top: 100px;
  padding-bottom: 130px;
  padding-left: 60px;
  padding-right: 60px;
  background-image: url('@/assets/broadcast-bg.png');
  background-repeat: no-repeat;
  background-position: bottom -215px left;
  position: relative;

  @media(max-width: 768px){
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
    background-image: none;
  }
}

.content-wrapper {
  .section-title {
    margin-bottom: 40px;

    @media(max-width: 768px){
      margin-bottom: 25px;
    }
  }

  .video {
    height: 526px;
    background-image: url('@/assets/broadcast-plug.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    cursor: pointer;

    @media(max-width: 999px){
      height: 330px;
    }

    @media(max-width: 768px){
      height: 210px;
    }
  }
}

.app-decoration {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  margin-right: 40px;

  @media(max-width: 999px){
    display: none;
  }
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  a {
    &:hover {
      text-decoration: none;
      color: $white;
    }
  }
}
</style>
