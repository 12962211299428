<template>
  <div class="home">
    <Promo />

    <Broadcast />

    <Schedule />

    <Crew />

    <AskQuestion />
  </div>
</template>

<script>
import Crew from '@/components/Crew'
import Promo from '@/components/Promo'
import Schedule from '@/components/Schedule'
import Broadcast from '@/components/Broadcast'
import AskQuestion from '@/components/AskQuestion'

export default {
  name: 'HomeView',

  components: {
    Crew,
    Promo,
    Schedule,
    Broadcast,
    AskQuestion,
  }
}
</script>

<style lang="scss" scoped>
.home {
}
</style>
