<template>
  <section class="wrapper"
           id="ask-question">
    <div class="content-wrapper">
      <div class="content-form">
        <div class="form-title">
          Задайте свой вопрос спикеру
        </div>

        <form ref="form"
              class="form"
              @submit.stop.prevent="submit">
          <div class="d-flex justify-content-between"
               style="margin-bottom: 5px;">
            <b-form-group
              class="position-relative w-47"
              id="name-input"
              label-for="name-input"
              invalid-feedback="Введите корректное имя"
            >
              <b-form-input
                id="name-input"
                placeholder="Ваше имя"
                required
                class="form-control input"
                type="text"
                v-model="$v.item.name.$model"
                :state="validateState($v.item.name)"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="position-relative w-47"
              id="email-input"
              label-for="email-input"
              invalid-feedback="Введите корректную почту"
            >
              <b-form-input
                id="email-input"
                placeholder="Почта"
                required
                class="form-control input"
                type="text"
                v-model="$v.item.email.$model"
                :state="validateState($v.item.email)"
              ></b-form-input>
            </b-form-group>
          </div>

          <b-form-group
            class="position-relative"
            id="select-input"
            label-for="select-input"
            invalid-feedback="Выберите спикера"
          >
            <b-form-input
              readonly
              id="select-input"
              @click="list = !list"
              placeholder="Выберите cпикера"
              required
              class="select"
              :class="{ active: list }"
              type="text"
              v-model="$v.item.subject.$model"
              :state="validateState($v.item.subject)"
            ></b-form-input>

            <div class="select-list"
                 v-if="list">
              <div class=""
                   v-for="option in options"
                   :key="option.name"
                   @click="setSubject(option.name)"
                   ref="optionName">
                {{ option.name }}
              </div>
            </div>

            <img src="@/assets/arrow-down.svg"
                 class="select-icon"
                 :class="{ 'active' : list }" />
          </b-form-group>

          <b-form-group
            class="position-relative"
            id="textarea"
            label-for="textarea"
            invalid-feedback="Поле некорректно"
          >
            <b-form-textarea
              id="textarea"
              placeholder="Напишите ваш вопрос"
              rows="3"
              class="textarea"
              max-rows="6"
              v-model="$v.item.msg.$model"
              :state="validateState($v.item.msg)"
              required
            ></b-form-textarea>
          </b-form-group>
        </form>

        <div class="button-wrapper">
          <b-button class="button rose submit-button"
                    @click="submit"
                    :disabled="disabledBtn">
            Отправить
          </b-button>
        </div>
      </div>
    </div>

    <template>
      <div class="thanks">
        <b-modal
          id="thanks-modal"
          ref="modal"
          title=""
          hide-footer
          hide-header
          centered
        >
        <div class="header">
          <h3>
            Ваш вопрос отправлен
          </h3>

          <p class="modal-text">
            Мы свяжемся с вами в ближайшее время и отправим <br> ответ на указанную почту
            <span>{{ this.item.email }}</span>
          </p>
        </div>

        <div class="d-flex justify-content-center">
          <b-button class="button rose"
                    @click="resetForm">Понятно, спасибо!</b-button>
        </div>
        </b-modal>
      </div>
    </template>

    <img class="plane"
         src="@/assets/airplane.png"/>
  </section>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const text = helpers.regex('text', /^[?!,.а-яА-ЯёЁ\s]+$/)
const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)

export default {
  name: 'AskQuestion',

  mixins: [validationMixin],

  validations: {
    item: {
      msg: {
        required,
        minLength: minLength(10)
      },
      subject: {
        required,
      },
      name: {
        required,
        minLength: minLength(2),
        text
      },
      email: {
        required,
        minLength: minLength(4),
        email
      }
    }
  },

  data: () => ({
    item: {
      msg: '',
      subject: '',
      name: '',
      email: ''
    },
    list: '',
    options: [
      { value: 'Александр Тихомиров', name: 'Александр Тихомиров' },
      { value: 'Ирина Кузнецова', name: 'Ирина Кузнецова' },
      { value: 'Наталия Аганезова', name: 'Наталия Аганезова' },
      { value: 'Юлия Крылова', name: 'Юлия Крылова' },
      { value: 'Анна Похитонова', name: 'Анна Похитонова' },
    ],
  }),

  watch: {
    disabledBtn(){
      if (!this.$v.item.$anyError){
        return false
      }
    }
  },


  computed: {
    disabledBtn(){
      if ((this.$v.item.$anyError) || (this.item.name.length < 1) || (this.item.email.length < 1)) {
        return true
      }
    }
  },

  methods: {
    validateState(item) {
      const { $dirty, $error } = item
      return $dirty ? !$error : null
    },

    setSubject(optionName) {
      this.item.subject = optionName

      this.list = false
    },

    resetForm() {
      this.$v.$reset()

      this.item.name = '',
      this.item.email = '',
      this.item.msg = '',
      this.item.subject = ''

      this.$bvModal.hide('thanks-modal')
    },

    submit() {
      this.$v.item.$touch()

      if (this.$v.item.$anyError)
        return false

      axios.post('feedback/send', {
        email: this.item.email,
        name: this.item.name,
        speaker: this.item.subject,
        message: this.item.msg,
      }).then((response) => {
        this.$bvModal.show('thanks-modal')
      }).catch((error) => {
        console.log(error)
        // if (error.response.data.message){
        //   this.$emit('getErrorText', error.response.data.message)
        //
        //   this.closeModal()
        //   this.$bvModal.show('error-modal')
        // }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  background-image: url('@/assets/question-section-bg.png');
  background-position: top center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 410px;
  position: relative;
  align-items: center;
  padding: 0 20px;
  padding-bottom: 130px;

  @media(max-width: 768px){
    min-height: fit-content;
    padding: 30px 20px;
  }
}

.content-wrapper {
  display: flex;
  justify-content: flex-end;

  @media(max-width: 1200px){
    justify-content: center;
  }
}

h3 {
  width: 100%;
  margin-top: 30px !important;
  margin-bottom: 30px;
  font-weight: 400;
  font-family: $regular;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  color: $violet;
  text-align: center;

  @media(max-width: 768px){
    font-size: 20px;
  }
}

.modal-text {
  text-align: center;
  margin-bottom: 35px;
  font-family: $regular;

  span {
    color: $rose;
  }
}

.content-form {
  width: 100%;
  max-width: 610px;
  height: fit-content;
  background-color: $white;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(34, 60, 80, 0.14);
  -moz-box-shadow: 0px 0px 11px 0px rgba(34, 60, 80, 0.14);
  box-shadow: 0px 0px 11px 0px rgba(34, 60, 80, 0.14);

  .form-title {
    background-color: $rose;
    padding: 15px 20px;
    font-weight: 600;
    font-family: $regular;
    font-size: 21px;
    color: $white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @media(max-width: 768px){
      padding: 15px 10px;
    }

    @media(max-width: 370px){
      font-size: 16px;
    }
  }

  .form {
    padding: 16px 20px;

    @media(max-width: 370px){
      padding: 10px;
    }
  }

  .textarea {
    margin-top: 25px;
    min-height: 130px;
    padding: 15px 20px;
    font-size: 16px;
    font-family: $regular;
    border-radius: 5px !important;
    border-color: #D9D9D9;
    transition: .5s all;
    position: relative;
    margin-bottom: 0 !important;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $rose;
    }

    &::placeholder {
      font-weight: 100;
      color: $dark-grey;
    }
  }

  .form-control,
  .select {
    height: 56px;
    width: 100%;
    font-size: 16px;
    position: relative;
    background-color: $white !important;
    margin-bottom: 20px;
    padding-left: 20px;
    transition: .2s all !important;
    border: 1px solid #D9D9D9;
    font-family: $regular;

    &.active {
      border: 1px solid $rose !important;
      border-bottom: none !important;
    }

    &:focus,
    &:active {
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 100;
      color: $dark-grey;
    }
  }

  .input {
    margin-bottom: 0;
    margin-top: 0;
  }

  .select-icon {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 15px;
    margin-top: 23px;
    transition: .5s all;

    &.active {
      transform: rotate(180deg);
    }
  }

  .select-list {
    position: absolute;
    margin-top: -10px;
    width: 100%;
    z-index: 99999;
    background-color: $white;
    border: 1px solid $rose;
    border-top: none;

    div {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 25px;
      background-color: $white;
      cursor: pointer;
      font-family: $regular;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      color: $violet;
      transition: .3s all;

      &:hover {
        background-color: $rose;
        color: $white;
      }

      &.active {
        background-color: $rose;
        color: $white;
      }
    }
  }
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -15px;

  .submit-button {
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: .5s all;
    border-radius: 80px !important;
    max-width: 130px !important;
    padding: 0 !important;
    height: 40px !important;
    width: 100%;

    &:hover {
      opacity: .85;
    }

    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }

    &.disabled {
      pointer-events: none !important;
      background-color: #4D4D4D !important;
      opacity: .9 !important;
    }
  }
}

.plane {
  max-width: 790px;
  position: absolute;
  left: 15px;
  top: 0;
  transition-property: transform;

  -webkit-animation: flying 1.9s infinite alternate;
  -moz-animation: flying 1.9s infinite alternate;
  -ms-animation: flying 1.9s infinite alternate;
  -o-animation: flying 1.9s infinite alternate;
  animation: flying 1.9s infinite alternate;
  animation-timing-function: linear;

  @media(max-width: 1700px){
    max-width: 600px;
  }

  @media(max-width: 1400px){
    max-width: 500px;
  }

  @media(max-width: 1200px){
    display: none;
  }
}

@keyframes flying {
  from {
    transform: rotate(-1deg);
  }

  to {
    transform: rotate(1.5deg);
  }
}
</style>
