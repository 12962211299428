<template>
  <section class="wrapper"
           id="crew">
    <div class="content-wrapper">
      <h1 class="section-title">
        Экипаж
      </h1>

      <div class="note-mobile mobile"
           v-click-outside="toggleTooltip1"
           v-if="tooltip1">
        <div>
          Доктор медицинских наук, профессор кафедры акушерства и гинекологии
          МГМСУ им. А.И. Евдокимова, лауреат премии правительства РФ в области
          науки и техники, заслуженный врач РФ
        </div>
      </div>

      <div class="note-mobile mobile"
           v-click-outside="toggleTooltip2"
           v-if="tooltip2">
        <div>
          Пилот реактивного самолета, <br> участница «Русских авиационных гонках»
        </div>
      </div>

      <div class="note-mobile mobile"
           v-click-outside="toggleTooltip3"
           v-if="tooltip3">
        <div>
          Руководитель отдела по связям <br> с общественностью компании «Гедеон Рихтер» в России
        </div>
      </div>

      <div class="note-mobile mobile"
           v-click-outside="toggleTooltip4"
           v-if="tooltip4">
        <div>
          Заместитель генерального директора по научно-исследовательской работе
          ООО «Витбиомед», президент международной ассоциации гинекологов,
          эндокринологов и терапевтов
        </div>
      </div>

      <div class="note-mobile mobile note-big"
           v-click-outside="toggleTooltip5"
           v-if="tooltip5">
        <div>
          Профессор кафедры акушерства и гинекологии, ФГБОУ ВО «Северо-западный
          государственный медицинский университет им. И.И. Мечникова» Минздрава
          России. Член правления Европейского общества по контрацепции и репродуктивному здоровью (ESC)
        </div>
      </div>

      <div class="crew-wrapper mobile">
        <div class="crew-item">
          <div class="note-info note-big desktop"
               v-click-outside="toggleTooltip5"
               v-if="tooltip5">
               Профессор кафедры акушерства и гинекологии, ФГБОУ ВО «Северо-западный
               государственный медицинский университет им. И.И. Мечникова» Минздрава
               России. Член правления Европейского общества по контрацепции и репродуктивному здоровью (ESC)
          </div>

          <img src="@/assets/note.svg"
               @click="toggleTooltip5"
               v-if="!tooltip5"
               class="note" />

          <img src="@/assets/note-active.svg"
               @click="toggleTooltip5"
               v-if="tooltip5"
               class="note" />

          <img src="@/assets/crew-5.png"
               class="pic" />

          <div class="name">Наталия Аганезова</div>

          <div class="sub-name">Профессор, доктор медицинских наук</div>
        </div>

        <div class="crew-item">
          <div class="note-info desktop"
               v-click-outside="toggleTooltip4"
               v-if="tooltip4">
               Заместитель генерального директора по научно-исследовательской работе
               ООО «Витбиомед», президент международной ассоциации гинекологов,
               эндокринологов и терапевтов
          </div>

          <img src="@/assets/note.svg"
               @click="toggleTooltip4"
               v-if="!tooltip4"
               class="note" />

          <img src="@/assets/note-active.svg"
               @click="toggleTooltip4"
               v-if="tooltip4"
               class="note" />

          <img src="@/assets/crew-4.png"
               class="pic" />

          <div class="name">Ирина Кузнецова</div>

          <div class="sub-name">Профессор, доктор медицинских наук</div>
        </div>

        <div class="crew-item">
          <div class="note-info desktop"
               v-click-outside="toggleTooltip1"
               v-if="tooltip1">
            Доктор медицинских наук, профессор кафедры акушерства и гинекологии
            МГМСУ им. А.И. Евдокимова, лауреат премии правительства РФ в области
            науки и техники, заслуженный врач РФ
          </div>

          <img src="@/assets/note.svg"
               @click="toggleTooltip1"
               v-if="!tooltip1"
               class="note" />

          <img src="@/assets/note-active.svg"
               @click="toggleTooltip1"
               v-if="tooltip1"
               class="note" />

          <img src="@/assets/crew-1.png"
               class="pic" />

          <div class="name">Александр Тихомиров</div>

          <div class="sub-name">Профессор, доктор медицинских наук</div>
        </div>

        <div class="crew-item">
          <div class="note-info note-info__2 desktop"
               v-click-outside="toggleTooltip2"
               v-if="tooltip2">
               Пилот реактивного самолета, <br> участница «Русских авиационных гонках»
          </div>

          <img src="@/assets/note.svg"
               @click="toggleTooltip2"
               v-if="!tooltip2"
               class="note" />

          <img src="@/assets/note-active.svg"
               @click="toggleTooltip2"
               v-if="tooltip2"
               class="note" />

          <img src="@/assets/crew-2.png"
               class="pic" />

          <div class="name">Юлия Крылова</div>

          <div class="sub-name">Специальный гость</div>
        </div>

        <div class="crew-item">
          <div class="note-info note-info__3 desktop"
               v-click-outside="toggleTooltip3"
               v-if="tooltip3">
               Руководитель отдела по связям <br> с общественностью компании «Гедеон Рихтер» в России
          </div>

          <img src="@/assets/note.svg"
               @click="toggleTooltip3"
               v-if="!tooltip3"
               class="note" />

          <img src="@/assets/note-active.svg"
               @click="toggleTooltip3"
               v-if="tooltip3"
               class="note" />

          <img src="@/assets/crew-3.png"
               class="pic" />

          <div class="name">Анна Похитонова</div>

          <div class="sub-name">Модератор форсайт-<br>сессии</div>
        </div>
      </div>

      <div class="crew-wrapper desktop">
        <div class="crew-item"
             v-if="index == 1">
          <div class="note-info note-big desktop"
               v-click-outside="toggleTooltip5"
               v-if="tooltip5">
               Профессор кафедры акушерства и гинекологии, ФГБОУ ВО «Северо-западный
               государственный медицинский университет им. И.И. Мечникова» Минздрава
               России. Член правления Европейского общества по контрацепции и репродуктивному здоровью (ESC)
          </div>

          <img src="@/assets/note.svg"
               @click="toggleTooltip5"
               v-if="!tooltip5"
               class="note" />

          <img src="@/assets/note-active.svg"
               @click="toggleTooltip5"
               v-if="tooltip5"
               class="note" />

          <img src="@/assets/crew-5.png"
               class="pic" />

          <div class="name">Наталия Аганезова</div>

          <div class="sub-name">Профессор, доктор медицинских наук</div>
        </div>

        <div class="crew-item"
             v-if="index == 1 || index == 2">
          <div class="note-info desktop"
               v-click-outside="toggleTooltip4"
               v-if="tooltip4">
               Заместитель генерального директора по научно-исследовательской работе
               ООО «Витбиомед», президент международной ассоциации гинекологов,
               эндокринологов и терапевтов
          </div>

          <img src="@/assets/note.svg"
               @click="toggleTooltip4"
               v-if="!tooltip4"
               class="note" />

          <img src="@/assets/note-active.svg"
               @click="toggleTooltip4"
               v-if="tooltip4"
               class="note" />

          <img src="@/assets/crew-4.png"
               class="pic" />

          <div class="name">Ирина Кузнецова</div>

          <div class="sub-name">Профессор, доктор медицинских наук</div>
        </div>

        <div class="crew-item"
             v-if="index == 1 || index == 2 || index == 3">
          <div class="note-info desktop"
               v-click-outside="toggleTooltip1"
               v-if="tooltip1">
            Доктор медицинских наук, профессор кафедры акушерства и гинекологии
            МГМСУ им. А.И. Евдокимова, лауреат премии правительства РФ в области
            науки и техники, заслуженный врач РФ
          </div>

          <img src="@/assets/note.svg"
               @click="toggleTooltip1"
               v-if="!tooltip1"
               class="note" />

          <img src="@/assets/note-active.svg"
               @click="toggleTooltip1"
               v-if="tooltip1"
               class="note" />

          <img src="@/assets/crew-1.png"
               class="pic" />

          <div class="name">Александр Тихомиров</div>

          <div class="sub-name">Профессор, доктор медицинских наук</div>
        </div>

        <div class="crew-item"
             v-if="index == 2 || index == 3">
          <div class="note-info note-info__2 desktop"
               v-click-outside="toggleTooltip2"
               v-if="tooltip2">
               Пилот реактивного самолета, <br> участница «Русских авиационных гонках»
          </div>

          <img src="@/assets/note.svg"
               @click="toggleTooltip2"
               v-if="!tooltip2"
               class="note" />

          <img src="@/assets/note-active.svg"
               @click="toggleTooltip2"
               v-if="tooltip2"
               class="note" />

          <img src="@/assets/crew-2.png"
               class="pic" />

          <div class="name">Юлия Крылова</div>

          <div class="sub-name">Специальный гость</div>
        </div>

        <div class="crew-item"
             v-if="index == 3">
          <div class="note-info note-info__3 desktop"
               v-click-outside="toggleTooltip3"
               v-if="tooltip3">
               Руководитель отдела по связям <br> с общественностью компании «Гедеон Рихтер» в России
          </div>

          <img src="@/assets/note.svg"
               @click="toggleTooltip3"
               v-if="!tooltip3"
               class="note" />

          <img src="@/assets/note-active.svg"
               @click="toggleTooltip3"
               v-if="tooltip3"
               class="note" />

          <img src="@/assets/crew-3.png"
               class="pic" />

          <div class="name">Анна Похитонова</div>

          <div class="sub-name">Модератор форсайт-<br>сессии</div>
        </div>
      </div>

      <div class="navs desktop">
        <img src="@/assets/arrow-left.svg"
             :class="{ 'disabled' : (index == 1) }"
             @click="prev"
             class="prev" />

        <img src="@/assets/line.svg" />

        <img src="@/assets/arrow-right.svg"
             @click="next"
             :class="{ 'disabled' : (index == 3) }"
             class="next" />
      </div>
    </div>
  </section>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: 'Crew',

  directives: {
    clickOutside: vClickOutside.directive
  },

  data: () => ({
    index: 1,
    tooltip1: false,
    tooltip2: false,
    tooltip3: false,
    tooltip4: false,
    tooltip5: false
  }),

  computed: {
  },

  methods: {
    prev(){
      if (this.index > 1){
        this.index--
      }
    },

    next(){
      if (this.index < 3){
        this.index++
      }
    },

    toggleTooltip1() {
      this.tooltip1 = !this.tooltip1
    },

    toggleTooltip2() {
      this.tooltip2 = !this.tooltip2
    },

    toggleTooltip3() {
      this.tooltip3 = !this.tooltip3
    },

    toggleTooltip4() {
      this.tooltip4 = !this.tooltip4
    },

    toggleTooltip5() {
      this.tooltip5 = !this.tooltip5
    },
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  padding-top: 100px;
  padding-bottom: 130px;
  padding-left: 60px;
  padding-right: 60px;

  @media(max-width: 576px){
    padding-right: 0;
    padding-left: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

.content-wrapper {
  position: relative;

  .note-mobile {
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: $rose;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    right: 50%;
    max-width: 300px;
    max-height: 100px;
    color: $white;
    font-size: 12px;
    font-family: $regular;
    font-weight: 400;
    justify-content: center;
    padding: 20px;
    text-align: center;
    align-items: center;
    margin-top: 0px;

    @media(max-width: 999px){
      display: flex !important;
    }
  }
}

.mobile {
  @media(max-width: 999px){
    display: flex !important;
  }
}

.crew-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  overflow-y: visible;
  padding-top: 150px;
  margin-top: -150px;

  @media(max-width: 999px){
    overflow-x: scroll;
  }

  .crew-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $regular;
    position: relative;
    width: fit-content;
    margin-right: 80px;

    &:last-child {
      margin-right: 0;
    }

    @media(max-width: 768px){
      margin-right: 40px;
    }

    .note-info {
      position: absolute;
      top: 0;
      right: 0;
      background-image: url('@/assets/tooltip-bg.png');
      background-repeat: no-repeat;
      width: 330px;
      height: 100%;
      background-position: bottom left;
      max-height: 100px;
      z-index: 999;
      margin-right: -340px;
      margin-top: -70px;
      z-index: 999;
      background-size: 100% 100%;
      color: $white;
      font-size: 12px;
      font-family: $regular;
      padding: 15px;
      font-weight: 400;
      padding-left: 20px;

      &__2 {
        max-height: 65px;
        margin-top: -40px;
        width: 290px;
        margin-right: -300px;
      }

      &__3 {
        background-image: url('@/assets/tooltip-left-bg.svg');
        background-size: cover;
        background-position: bottom right;
        margin-right: 0;
        max-height: 80px;
        margin-right: 40px;
        width: 230px;
        padding-left: 15px;
        margin-top: -50px;
      }
    }

    .note {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 4;
      cursor: pointer;
      transition: .5s all;

      &:hover {
        opacity: .85;
      }
    }

    .pic {
      max-width: 240px;
      border-radius: 200px;
    }

    .name {
      color: $blue;
      font-size: 21px;
      font-weight: 600;
      text-align: center;
    }

    .sub-name {
      text-align: center;
      color: $dark-grey;
      font-size: 16px;
      height: 50px;
      font-weight: 600;
    }
  }
}

.note-big {
  max-height: 130px !important;

  @media(max-width: 999px){
    max-height: 160px !important;
  }
}

.navs {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;

  .prev,
  .next {
    cursor: pointer;

    &:hover {
      opacity: .9;
    }

    &.disabled {
      pointer-events: none;
      filter: grayscale(1);
    }
  }
}
</style>
