<template>
  <div>
    <b-modal
      id="registration-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <h3>
          Регистрация <br>
        </h3>
      </div>

      <div class=""
           style="padding: 0 30px;">
        <form ref="form"
              class="form"
              @submit.stop.prevent="submit">
          <b-form-group
            class="position-relative w-100"
            id="name-input"
            label-for="name-input"
            invalid-feedback="Введите корректное имя"
          >
            <b-form-input
              id="name-input"
              placeholder="Имя"
              required
              class="form-control input"
              type="text"
              v-model="$v.item.name.$model"
              :state="validateState($v.item.name)"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            class="position-relative w-100"
            id="email-input"
            label-for="email-input"
            invalid-feedback="Введите корректную почту"
          >
            <b-form-input
              id="email-input"
              placeholder="Почта"
              required
              class="form-control input"
              type="text"
              v-model="$v.item.email.$model"
              :state="validateState($v.item.email)"
            ></b-form-input>
          </b-form-group>

          <b-form-checkbox
            id="checkbox-policies"
            class="mt-2 checkbox"
            v-model="$v.item.policies.$model"
            :state="validateState($v.item.policies)"
            name="policies"
            required
            unchecked-value=""
          >
            Даю согласие на обработку персональных данных
          </b-form-checkbox>
        </form>

        <div class="button-wrapper">
          <b-button class="button rose submit-button"
                    @click="submit"
                    :disabled="disabledBtn">
            Зарегистрироваться
          </b-button>
        </div>
      </div>
    </b-modal>

    <div>
      <b-modal
        id="success-modal"
        ref="modal"
        title=""
        hide-footer
        hide-header
        centered
      >
      <div class="header">
        <h3>
          Вы успешно зарегистрировались!
        </h3>
      </div>

      <div class="d-flex justify-content-center">
        <b-button class="button rose"
                  @click="$bvModal.hide('success-modal')">Понятно, спасибо!</b-button>
      </div>
      </b-modal>
    </div>

    <div>
      <b-modal
        id="error-modal"
        ref="modal"
        title=""
        hide-footer
        hide-header
        centered
      >
      <div class="header">
        <h3>
          {{ errorText }}
        </h3>
      </div>

      <div class="d-flex justify-content-center">
        <b-button class="button rose"
                  @click="$bvModal.hide('error-modal')">Понятно, спасибо!</b-button>
      </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const text = helpers.regex('text', /^[?!,.а-яА-ЯёЁ\s]+$/)
const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)

  export default {
    data: () => ({
      item: {
        policies: '',
        name: '',
        email: ''
      },
      errorText: ''
    }),

    mixins: [validationMixin],

    validations: {
      item: {
        name: {
          required,
          minLength: minLength(2),
          text
        },
        email: {
          required,
          minLength: minLength(4),
          email
        },
        policies: {
          required
        }
      }
    },

    watch: {
      disabledBtn(){
        if (!this.$v.item.$anyError){
          return false
        }
      }
    },

    computed: {
      disabledBtn(){
        if ((this.$v.item.$anyError) || (this.item.name.length < 1) || (this.item.email.length < 1)) {
          return true
        }
      }
    },

    methods: {
      validateState(item) {
        const { $dirty, $error } = item
        return $dirty ? !$error : null
      },

      closeModal() {
        this.$v.$reset()

        this.item.name = '',
        this.item.email = '',
        this.item.policies = ''

        this.$bvModal.hide('registration-modal')
      },

      submit() {
        this.$v.item.$touch()

        if (this.$v.item.$anyError)
          return false

        axios.post('registration', {
          email: this.item.email,
          name: this.item.name,
        }).then((response) => {
          this.closeModal()
          this.$bvModal.show('success-modal')
        }).catch((error) => {
          if (error.response.data.errors.email){
            this.closeModal()
            this.$bvModal.show('error-modal')
            this.errorText = "Данный e-mail уже зарегистрирован!"
          } else {
            this.closeModal()
            this.$bvModal.show('error-modal')
            this.errorText = "Возникла непредвиденная ошибка, попробуйте позже или обратитесь в техподдержку!"
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }

  h3 {
    width: 100%;
    margin-top: 50px !important;
    margin-bottom: 30px;
    font-weight: 400;
    font-family: $regular;
    font-size: 24px;
    text-align: center;
    color: $violet;

    @media(max-width: 768px){
      font-size: 20px;
    }
  }
}

.timer {
  font-weight: 700;
  margin-top: 20px;
  color: $violet;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  width: 100%;
  max-width: 345px;
  border-radius: 80px !important;
  margin-top: 30px;

  &:active,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: $rose !important;
  }

  &.disabled {
    pointer-events: none !important;
    background-color: #4D4D4D !important;
    opacity: .9 !important;
  }
}

.form-control {
  height: 50px;

  &:active,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: $rose !important;
  }
}
</style>
