<template>
  <section class="wrapper"
           id="promo">
    <div class="promo-content desktop">
      <div class="promo-title">
        Форсайт-сессия

        <p>Взлёт разрешён</p>

        <span>
          эксперты представляют инновационный <br> гормональный контрацептив
        </span>
      </div>

      <div class="timer">
        <div class="timer-title">Начало конференции через</div>

        <div class="timer-text">
          <countdown :time="time">
            <template slot-scope="props">{{ props.days }}<span class="small">д</span> :  {{ props.hours }}<span class="small">ч</span>  :  {{ props.minutes }}<span class="small">м</span></template>
          </countdown>
        </div>

        <div class="timer-subtext">4 февраля 2023</div>
      </div>

      <!-- <div class="button-wrapper">
        <button class="button violet"
                v-b-modal.registration-modal>Зарегистрироваться</button>
      </div> -->

      <div class="decor">
        <div class="date">4 февраля <br> в 11:00</div>

        <img src="@/assets/promo-small-decoration.png"
             class="" />
      </div>

      <img src="@/assets/promo-decoration.png"
           class="img" />
    </div>

    <div class="promo-content mobile">
      <div class="promo-title">
        Форсайт-сессия

        <p>Взлёт разрешён</p>

        <span>
          эксперты представляют инновационный <br> гормональный контрацептив
        </span>
      </div>

      <div class="timer">
        <div class="timer-title">Начало конференции через</div>

        <div class="timer-text">
          <countdown :time="time">
            <template slot-scope="props">{{ props.days }}<span class="small">д</span>  :  {{ props.hours }}<span class="small">ч</span>  :  {{ props.minutes }}<span class="small">м</span></template>
          </countdown>
        </div>

        <div class="timer-subtext">4 февраля 2023</div>
      </div>

      <!-- <div class="button-wrapper">
        <button class="button violet"
                v-b-modal.registration-modal>Зарегистрироваться</button>
      </div> -->

      <div class="decor">
        <div class="date">4 февраля <br> в 11:00</div>

        <img src="@/assets/promo-small-decoration.png"
             class="" />
      </div>

      <img src="@/assets/promo-decoration-mob.png"
           class="img" />
    </div>
  </section>
</template>

<script>

export default {
  name: 'Promo',

  data: () => ({
    time: 0,
  }),

  mounted() {
    this.getTime()
  },

  computed: {
  },

  methods: {
    getTime(){
      const now = new Date()
      const newYear = new Date("February 04, 2023 11:00:00")

      this.time = newYear - now
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  height: 100%;
  min-height: calc(100vh - 92px);
  background-image: url('@/assets/promo-bg@1920.png');
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 100vh;
  position: relative;

  @media(max-width: 1800px){
    background-position: left -200px top;
  }

  @media(max-width: 1440px){
    background-position: left top;
    background-image: url('@/assets/promo-bg.png');
  }

  @media(max-width: 1270px){
    background-position: left -210px top;
  }

  @media(max-width: 999px){
    min-height: calc(100vh - 64px);
    background-image: url('@/assets/promo-bg-mob.png');
    background-position: top center;
    background-size: 100%;
  }

  .img {
    position: absolute;
    right: 0;
    height: 100%;
    z-index: 1;

    @media(min-width: 1800px) and (max-height: 810px){
      width: 900px;
    }

    @media(max-width: 999px){
      width: 100%;
      height: auto;
      bottom: 0;
      max-height: 600px;
    }
  }

  .decor {
    margin-left: -10px;
    z-index: 2;
    margin-top: 15px;
    position: absolute;

    @media(max-width: 1440px){
      margin-left: -50px;
    }

    @media(max-width: 1270px){
      margin-left: -140px;
    }

    @media(max-width: 999px){
      bottom: 0;
      right: 0;
      margin-bottom: 400px;
      margin-right: 30px;
    }

    @media(max-width: 480px){
      margin-bottom: 345px;
      margin-right: -15px;
      margin-left: 0;
    }

    @media(max-width: 400px){
      margin-bottom: 300px;
    }

    @media(max-width: 330px){
      margin-bottom: 260px;
    }

    @media(min-width: 1600px) and (max-height: 910px){
      margin-left: 140px;
    }

    @media (min-width: 999px) and (max-height: 740px){
      margin-left: 50px;
    }

    @media (min-width: 999px) and (max-height: 700px){
      margin-left: 30px;
    }
  }

  .date {
    text-transform: uppercase;
    color: $white;
    font-family: $regular;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    position: absolute;
    margin-left: 35px;
    margin-top: 50px;
  }

  .promo-title {
    margin-top: 310px;
    margin-left: 160px;
    position: absolute;
    color: $white;
    font-family: $regular;
    font-size: 42px;
    line-height: 100%;
    z-index: 2;

    @media(max-width: 1440px){
      margin-top: 180px;
      margin-left: 120px;
      font-size: 32px;
    }

    @media(max-width: 1270px){
      margin-left: 0;
    }

    @media(max-width: 999px){
      left: 0;
      bottom: 0;
      margin-bottom: 180px;
      margin-left: 60px;
    }

    @media(max-width: 576px){
      font-size: 22px;
      margin-bottom: 230px;
    }

    @media(max-width: 480px){
      margin-bottom: 180px;
      margin-left: 30px;
    }

    @media(max-width: 400px){
      margin-bottom: 170px;
    }

    @media(max-width: 320px){
      font-size: 14px;
      line-height: 14px;
    }

    @media(min-width: 1600px) and (max-height: 910px){
      margin-top: 200px;
      margin-left: 240px;
    }

    @media (min-width: 999px) and (max-height: 740px){
      margin-top: 120px;
      margin-left: 200px;
      font-size: 24px;
    }

    span {
      font-size: 26px;
      line-height: 34px;

      @media(max-width: 576px){
        font-size: 20px;
      }

      @media(max-width: 480px){
        font-size: 16px;
      }

      @media(max-width: 320px){
        font-size: 12px;
        line-height: 20px;
      }

      @media (min-width: 999px) and (max-height: 700px){
        font-size: 20px;
      }
    }

    p {
      font-size: 80px;
      margin-bottom: 0;
      font-weight: 500;
      line-height: 72px;
      margin-bottom: 30px;

      @media(max-width: 1440px){
        font-size: 64px;
      }

      @media(max-width: 576px){
        font-size: 42px;
        line-height: 50px;
        margin-bottom: 20px;
      }

      @media(max-width: 480px){
        margin-bottom: 10px;
      }

      @media(max-width: 330px){
        font-size: 32px;
      }

      @media (min-width: 999px) and (max-height: 700px){
        font-size: 48px;
        margin-bottom: 20px;
      }
    }
  }

  .button-wrapper {
    position: absolute;
    z-index: 2;
    margin-top: 740px;
    margin-left: 320px;

    @media(max-width: 1440px){
      margin-top: 560px;
      margin-left: 240px;
    }

    @media(max-width: 1270px){
      margin-left: 120px;
    }

    @media(max-width: 1200px){
      margin-left: 70px;
    }

    @media(max-width: 999px){
      bottom: 0;
      left: 50%;
      right: 50%;
      margin-left: 0;
      transform: translateX(-50%);
      margin-bottom: 10px;
      margin-left: -80px;
      margin-right: 0;
    }

    @media(min-width: 1600px) and (max-height: 910px){
      margin-top: 680px;
      margin-left: 450px;
    }

    @media(min-width: 1600px) and (max-height: 810px){
      margin-top: 650px;
    }
  }

  .timer {
    position: absolute;
    z-index: 2;
    margin-top: 580px;
    margin-left: 310px;
    width: 100%;
    max-width: 380px;
    background-image: url('@/assets/promo-timer.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 140px;

    @media(max-width: 1440px){
      margin-top: 435px;
      margin-left: 235px;
      max-width: 280px;
      height: 110px;
    }

    @media(max-width: 1270px){
      margin-left: 120px;
    }

    @media(max-width: 1200px){
      margin-left: 70px;
    }

    @media(max-width: 999px){
      bottom: 0;
      left: 50%;
      right: 50%;
      margin-left: 0;
      transform: translateX(-50%);
      margin-bottom: 55px;
    }

    @media(min-width: 1600px) and (max-height: 910px){
      margin-top: 500px;
      margin-left: 440px;
    }

    @media (min-width: 999px) and (max-height: 740px){
      margin-top: 420px;
      margin-left: 320px;
    }

    @media (min-width: 999px) and (max-height: 700px){
      margin-top: 340px;
      margin-left: 290px;
    }

    .timer-title {
      font-size: 22px;
      color: $dark-grey;
      font-family: $regular;
      padding-left: 65px;
      padding-top: 9px;

      @media(max-width: 1440px){
        padding-left: 45px;
        padding-top: 6px;
        font-size: 16px;
      }
    }

    .timer-text {
      text-align: center;
      font-size: 60px;
      color: $violet;
      font-weight: 300;
      line-height: 60px;

      @media(max-width: 1440px){
        font-size: 44px;
        line-height: 44px;
      }

      .small {
        font-size: 16px;
        font-weight: 700;
      }
    }

    .timer-date-text {
      font-weight: 500;
      font-size: 10px;
      font-family: $regular;
      color: $dark-grey;
      width: 100%;
      display: flex;
      margin-top: -5px;
      justify-content: center;

      @media(max-width: 1440px){
        font-size: 8px;
      }

      div {
        margin: 0 37px;

        @media(max-width: 1440px){
          margin: 0 30px;
        }
      }
    }

    .timer-subtext {
      font-weight: 700;
      font-size: 16px;
      font-family: $regular;
      color: #BABABA;
      width: 100%;
      margin-top: -5px;
      text-align: center;

      @media(max-width: 1440px){
        font-size: 12px;
      }
    }

    .timer-img {
      max-width: 400px;

      @media(max-width: 1440px){
        max-width: 280px;
      }
    }
  }
}
</style>
